* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

.frame-ASD {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
}

.section {
  scroll-snap-align: start;
  display: flex;
  background-color: #f0f0f0;
  display: grid;
  place-items: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
}

.faq-item {
  margin-bottom: 10px;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  overflow: hidden;
}

.faq-question {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.toggle-btn {
  border: none;
  background: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.faq-answer {
  padding: 10px;
  display: none;
  /* background-color: #fff; */
}

.faq-answer.open {
  display: block;
}




.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}


.triangle-image {
  height: 250px;
  width: 450px;
  margin-top: 322px;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}



@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}



@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}








.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 992px) {

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

/* I. REFERENCES */

/* II. GENERAL
***********************/

/*--------------------------------------------------------------
Reset
--------------------------------------------------------------*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  -webkit-text-size-adjust: 100%;
  /* Prevents iOS text size adjust after orientation change, without disabling user zoom */
  -ms-text-size-adjust: 100%;
  /* www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  /* apply a natural box layout model to all elements; see http://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
  -webkit-box-sizing: border-box;
  /* Not needed for modern webkit but still used by Blackberry Browser 7.0; see http://caniuse.com/#search=box-sizing */
  -moz-box-sizing: border-box;
  /* Still needed for Firefox 28; see http://caniuse.com/#search=box-sizing */
  box-sizing: border-box;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav {
  display: block;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

table {
  /* tables still need 'cellspacing="0"' in the markup */
  border-collapse: separate;
  border-spacing: 0;
}

iframe,
embed,
figure,
figcaption {
  margin: 20px 0;
  max-width: 100%;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

blockquote,
q {
  quotes: "" "";
}

a:focus {
  outline: none;
}

a:hover,
a:active {
  outline: 0;
}

a img {
  border: 0;
}

/*--------------------------------------------
Initial Setup
----------------------------------------------*/

a {
  text-decoration: none;
}



/* img, */
video {
  max-width: 100%;
  vertical-align: top;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  height: auto;
  /* max-width: 100%; */
  vertical-align: middle;
}

audio,
canvas,
video {
  display: inline;
  zoom: 1;
}

html {
  font-size: 100%;
}

body {
  font-size: 14px;
  color: #737373;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
}

body,
button,
input,
textarea {
  font-family: 'Lato', sans-serif;
  line-height: 1;
}








li ul,
li ol {
  margin-left: 1.25em;
}

b,
strong {
  font-weight: 700;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

li,
figcaption {
  line-height: 24px;
}

blockquote {
  border-left: 4px solid #707070;
  border-left: 4px solid rgba(51, 51, 51, 0.7);
  color: #707070;
  color: rgba(51, 51, 51, 0.7);
  font-size: 18px;
  font-size: 1.8rem;
  font-style: italic;
  line-height: 1.6667;
  margin-bottom: 1.6667em;
  padding-left: 0.7778em;
}

blockquote p {
  margin-bottom: 1.6667em;
}

blockquote>p:last-child {
  margin-bottom: 0;
}

blockquote cite,
blockquote small {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
}

blockquote a {
  font-size: 16px;
  color: #ffa506;
}

blockquote a:hover {
  text-decoration: none;
}

blockquote em,
blockquote i,
blockquote cite {
  font-style: normal;
}

blockquote strong,
blockquote b {
  font-weight: 400;
}

blockquote.alignleft {
  margin: 0.3157894737em 1.4736842105em 1.473684211em 0;
}

blockquote.alignright {
  margin: 0.3157894737em 0 1.473684211em 1.4736842105em;
}

blockquote.aligncenter {
  margin-bottom: 1.473684211em;
}

address {
  font-style: italic;
  margin: 0 0 1.6em;
  line-height: 24px;
}

code,
kbd,
tt,
var,
samp,
pre {
  font-family: Inconsolata, monospace;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre {
  background-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid #eaeaea;
  border: 1px solid rgba(51, 51, 51, 0.1);
  line-height: 24px;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 0.8em;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

abbr[title] {
  border-bottom: 1px dotted #eaeaea;
  border-bottom: 1px dotted rgba(51, 51, 51, 0.1);
  cursor: help;
}

mark,
ins {
  background-color: #fff9c0;
  text-decoration: none;
}

sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  bottom: 1ex;
}

sub {
  top: .5ex;
}

small {
  font-size: 75%;
}

big {
  font-size: 125%;
}

hr {
  background-color: #eaeaea;
  background-color: rgba(51, 51, 51, 0.1);
  border: 0;
  height: 1px;
  margin: 15px 0;
}

table,
th,
td {
  border: 1px solid #eaeaea;
  border: 1px solid rgba(51, 51, 51, 0.1);
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 20px 0;
  table-layout: fixed;
  max-width: 100%;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: 700;
}

td {
  border-width: 0 1px 1px 0;
}

th,
td {
  padding: 1em;
}

del {
  opacity: 0.8;
}

/* Placeholder text color -- selectors need to be separate to work. */

::-webkit-input-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

:-moz-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

::-moz-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  opacity: 1;
}



textarea {
  max-width: 100%;
}

a {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

a:hover {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

dl {
  margin: 0 0 1.75em;
  line-height: 24px;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 0 10px;
}



.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-item {
  width: 100%;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  padding: 1.79104477%;
}

/* Alignments */

.alignleft {
  float: left;
  margin: 0.375em 1.75em 1.75em 0;
}

.alignright {
  float: right;
  margin: 0.375em 0 1.75em 1.75em;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}



.row:before,
.row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row:before,
.row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

/***************************Style***************************/

.cl {
  clear: both;
}

.flw {
  float: left;
  width: 100%;
}

/* Text align
--------------------------*/

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: left;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}



/* Grid system */

/* -------------------------------------------------- */

.fw-main-row,
.fw-main-row *,
.fw-main-row *:before,
.fw-main-row *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fw-container {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .fw-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .fw-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .fw-container {
    width: 1200px;
  }
}



.fw-row {
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;
}

.fw-row:before,
.fw-row:after {
  content: " ";
  display: table;
}

.fw-row:after {
  clear: both;
}

.fw-col-xs-1,
.fw-col-sm-1,
.fw-col-md-1,
.fw-col-lg-1,
.fw-col-xs-2,
.fw-col-sm-2,
.fw-col-md-2,
.fw-col-lg-2,
.fw-col-xs-3,
.fw-col-sm-3,
.fw-col-md-3,
.fw-col-lg-3,
.fw-col-xs-4,
.fw-col-sm-4,
.fw-col-md-4,
.fw-col-lg-4,
.fw-col-xs-5,
.fw-col-sm-5,
.fw-col-md-5,
.fw-col-lg-5,
.fw-col-xs-6,
.fw-col-sm-6,
.fw-col-md-6,
.fw-col-lg-6,
.fw-col-xs-7,
.fw-col-sm-7,
.fw-col-md-7,
.fw-col-lg-7,
.fw-col-xs-8,
.fw-col-sm-8,
.fw-col-md-8,
.fw-col-lg-8,
.fw-col-xs-9,
.fw-col-sm-9,
.fw-col-md-9,
.fw-col-lg-9,
.fw-col-xs-10,
.fw-col-sm-10,
.fw-col-md-10,
.fw-col-lg-10,
.fw-col-xs-11,
.fw-col-sm-11,
.fw-col-md-11,
.fw-col-lg-11,
.fw-col-xs-12,
.fw-col-sm-12,
.fw-col-md-12,
.fw-col-lg-12,
.fw-col-xs-15,
.fw-col-sm-15,
.fw-col-md-15,
.fw-col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

@media (min-width: 768px) {

  .fw-col-sm-1,
  .fw-col-sm-2,
  .fw-col-sm-3,
  .fw-col-sm-4,
  .fw-col-sm-5,
  .fw-col-sm-6,
  .fw-col-sm-7,
  .fw-col-sm-8,
  .fw-col-sm-9,
  .fw-col-sm-10,
  .fw-col-sm-11,
  .fw-col-sm-12,
  .fw-col-sm-15 {
    float: left;
  }

  .fw-col-sm-15 {
    width: 20%;
  }

  .fw-col-sm-12 {
    width: 100%;
  }

  .fw-col-sm-11 {
    width: 91.66666667%;
  }

  .fw-col-sm-10 {
    width: 83.33333333%;
  }

  .fw-col-sm-9 {
    width: 75%;
  }

  .fw-col-sm-8 {
    width: 66.66666667%;
  }

  .fw-col-sm-7 {
    width: 58.33333333%;
  }

  .fw-col-sm-6 {
    width: 50%;
  }

  .fw-col-sm-5 {
    width: 41.66666667%;
  }

  .fw-col-sm-4 {
    width: 33.33333333%;
  }

  .fw-col-sm-3 {
    width: 25%;
  }

  .fw-col-sm-2 {
    width: 16.66666667%;
  }

  .fw-col-sm-1 {
    width: 8.33333333%;
  }

  .fw-col-sm-pull-15 {
    right: 20%;
  }

  .fw-col-sm-pull-12 {
    right: 100%;
  }

  .fw-col-sm-pull-11 {
    right: 91.66666667%;
  }

  .fw-col-sm-pull-10 {
    right: 83.33333333%;
  }

  .fw-col-sm-pull-9 {
    right: 75%;
  }

  .fw-col-sm-pull-8 {
    right: 66.66666667%;
  }

  .fw-col-sm-pull-7 {
    right: 58.33333333%;
  }

  .fw-col-sm-pull-6 {
    right: 50%;
  }

  .fw-col-sm-pull-5 {
    right: 41.66666667%;
  }

  .fw-col-sm-pull-4 {
    right: 33.33333333%;
  }

  .fw-col-sm-pull-3 {
    right: 25%;
  }

  .fw-col-sm-pull-2 {
    right: 16.66666667%;
  }

  .fw-col-sm-pull-1 {
    right: 8.33333333%;
  }

  .fw-col-sm-pull-0 {
    right: auto;
  }

  .fw-col-sm-push-15 {
    left: 20%;
  }

  .fw-col-sm-push-12 {
    left: 100%;
  }

  .fw-col-sm-push-11 {
    left: 91.66666667%;
  }

  .fw-col-sm-push-10 {
    left: 83.33333333%;
  }

  .fw-col-sm-push-9 {
    left: 75%;
  }

  .fw-col-sm-push-8 {
    left: 66.66666667%;
  }

  .fw-col-sm-push-7 {
    left: 58.33333333%;
  }

  .fw-col-sm-push-6 {
    left: 50%;
  }

  .fw-col-sm-push-5 {
    left: 41.66666667%;
  }

  .fw-col-sm-push-4 {
    left: 33.33333333%;
  }

  .fw-col-sm-push-3 {
    left: 25%;
  }

  .fw-col-sm-push-2 {
    left: 16.66666667%;
  }

  .fw-col-sm-push-1 {
    left: 8.33333333%;
  }

  .fw-col-sm-push-0 {
    left: auto;
  }

  .fw-col-sm-offset-15 {
    margin-left: 20%;
  }

  .fw-col-sm-offset-12 {
    margin-left: 100%;
  }

  .fw-col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .fw-col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .fw-col-sm-offset-9 {
    margin-left: 75%;
  }

  .fw-col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .fw-col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .fw-col-sm-offset-6 {
    margin-left: 50%;
  }

  .fw-col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .fw-col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .fw-col-sm-offset-3 {
    margin-left: 25%;
  }

  .fw-col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .fw-col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .fw-col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 992px) {

  .fw-col-md-1,
  .fw-col-md-2,
  .fw-col-md-3,
  .fw-col-md-4,
  .fw-col-md-5,
  .fw-col-md-6,
  .fw-col-md-7,
  .fw-col-md-8,
  .fw-col-md-9,
  .fw-col-md-10,
  .fw-col-md-11,
  .fw-col-md-12,
  .fw-col-md-15 {
    float: left;
  }

  .fw-col-md-15 {
    width: 20%;
  }

  .fw-col-md-12 {
    width: 100%;
  }

  .fw-col-md-11 {
    width: 91.66666667%;
  }

  .fw-col-md-10 {
    width: 83.33333333%;
  }

  .fw-col-md-9 {
    width: 75%;
  }

  .fw-col-md-8 {
    width: 66.66666667%;
  }

  .fw-col-md-7 {
    width: 58.33333333%;
  }

  .fw-col-md-6 {
    width: 50%;
  }

  .fw-col-md-5 {
    width: 41.66666667%;
  }

  .fw-col-md-4 {
    width: 33.33333333%;
  }

  .fw-col-md-3 {
    width: 25%;
  }

  .fw-col-md-2 {
    width: 16.66666667%;
  }

  .fw-col-md-1 {
    width: 8.33333333%;
  }

  .fw-col-md-pull-15 {
    right: 20%;
  }

  .fw-col-md-pull-12 {
    right: 100%;
  }

  .fw-col-md-pull-11 {
    right: 91.66666667%;
  }

  .fw-col-md-pull-10 {
    right: 83.33333333%;
  }

  .fw-col-md-pull-9 {
    right: 75%;
  }

  .fw-col-md-pull-8 {
    right: 66.66666667%;
  }

  .fw-col-md-pull-7 {
    right: 58.33333333%;
  }

  .fw-col-md-pull-6 {
    right: 50%;
  }

  .fw-col-md-pull-5 {
    right: 41.66666667%;
  }

  .fw-col-md-pull-4 {
    right: 33.33333333%;
  }

  .fw-col-md-pull-3 {
    right: 25%;
  }

  .fw-col-md-pull-2 {
    right: 16.66666667%;
  }

  .fw-col-md-pull-1 {
    right: 8.33333333%;
  }

  .fw-col-md-pull-0 {
    right: auto;
  }

  .fw-col-md-push-15 {
    left: 20%;
  }

  .fw-col-md-push-12 {
    left: 100%;
  }

  .fw-col-md-push-11 {
    left: 91.66666667%;
  }

  .fw-col-md-push-10 {
    left: 83.33333333%;
  }

  .fw-col-md-push-9 {
    left: 75%;
  }

  .fw-col-md-push-8 {
    left: 66.66666667%;
  }

  .fw-col-md-push-7 {
    left: 58.33333333%;
  }

  .fw-col-md-push-6 {
    left: 50%;
  }

  .fw-col-md-push-5 {
    left: 41.66666667%;
  }

  .fw-col-md-push-4 {
    left: 33.33333333%;
  }

  .fw-col-md-push-3 {
    left: 25%;
  }

  .fw-col-md-push-2 {
    left: 16.66666667%;
  }

  .fw-col-md-push-1 {
    left: 8.33333333%;
  }

  .fw-col-md-push-0 {
    left: auto;
  }

  .fw-col-md-offset-15 {
    margin-left: 20%;
  }

  .fw-col-md-offset-12 {
    margin-left: 100%;
  }

  .fw-col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .fw-col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .fw-col-md-offset-9 {
    margin-left: 75%;
  }

  .fw-col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .fw-col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .fw-col-md-offset-6 {
    margin-left: 50%;
  }

  .fw-col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .fw-col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .fw-col-md-offset-3 {
    margin-left: 25%;
  }

  .fw-col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .fw-col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .fw-col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {

  .fw-col-lg-1,
  .fw-col-lg-2,
  .fw-col-lg-3,
  .fw-col-lg-4,
  .fw-col-lg-5,
  .fw-col-lg-6,
  .fw-col-lg-7,
  .fw-col-lg-8,
  .fw-col-lg-9,
  .fw-col-lg-10,
  .fw-col-lg-11,
  .fw-col-lg-12,
  .fw-col-lg-15 {
    float: left;
  }

  .fw-col-lg-15 {
    width: 20%;
  }

  .fw-col-lg-12 {
    width: 100%;
  }

  .fw-col-lg-11 {
    width: 91.66666667%;
  }

  .fw-col-lg-10 {
    width: 83.33333333%;
  }

  .fw-col-lg-9 {
    width: 75%;
  }

  .fw-col-lg-8 {
    width: 66.66666667%;
  }

  .fw-col-lg-7 {
    width: 58.33333333%;
  }

  .fw-col-lg-6 {
    width: 50%;
  }

  .fw-col-lg-5 {
    width: 41.66666667%;
  }

  .fw-col-lg-4 {
    width: 33.33333333%;
  }

  .fw-col-lg-3 {
    width: 25%;
  }

  .fw-col-lg-2 {
    width: 16.66666667%;
  }

  .fw-col-lg-1 {
    width: 8.33333333%;
  }

  .fw-col-lg-pull-15 {
    right: 20%;
  }

  .fw-col-lg-pull-12 {
    right: 100%;
  }

  .fw-col-lg-pull-11 {
    right: 91.66666667%;
  }

  .fw-col-lg-pull-10 {
    right: 83.33333333%;
  }

  .fw-col-lg-pull-9 {
    right: 75%;
  }

  .fw-col-lg-pull-8 {
    right: 66.66666667%;
  }

  .fw-col-lg-pull-7 {
    right: 58.33333333%;
  }

  .fw-col-lg-pull-6 {
    right: 50%;
  }

  .fw-col-lg-pull-5 {
    right: 41.66666667%;
  }

  .fw-col-lg-pull-4 {
    right: 33.33333333%;
  }

  .fw-col-lg-pull-3 {
    right: 25%;
  }

  .fw-col-lg-pull-2 {
    right: 16.66666667%;
  }

  .fw-col-lg-pull-1 {
    right: 8.33333333%;
  }

  .fw-col-lg-pull-0 {
    right: auto;
  }

  .fw-col-lg-push-15 {
    left: 20%;
  }

  .fw-col-lg-push-12 {
    left: 100%;
  }

  .fw-col-lg-push-11 {
    left: 91.66666667%;
  }

  .fw-col-lg-push-10 {
    left: 83.33333333%;
  }

  .fw-col-lg-push-9 {
    left: 75%;
  }

  .fw-col-lg-push-8 {
    left: 66.66666667%;
  }

  .fw-col-lg-push-7 {
    left: 58.33333333%;
  }

  .fw-col-lg-push-6 {
    left: 50%;
  }

  .fw-col-lg-push-5 {
    left: 41.66666667%;
  }

  .fw-col-lg-push-4 {
    left: 33.33333333%;
  }

  .fw-col-lg-push-3 {
    left: 25%;
  }

  .fw-col-lg-push-2 {
    left: 16.66666667%;
  }

  .fw-col-lg-push-1 {
    left: 8.33333333%;
  }

  .fw-col-lg-push-0 {
    left: auto;
  }

  .fw-col-lg-offset-15 {
    margin-left: 20%;
  }

  .fw-col-lg-offset-12 {
    margin-left: 100%;
  }

  .fw-col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .fw-col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .fw-col-lg-offset-9 {
    margin-left: 75%;
  }

  .fw-col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .fw-col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .fw-col-lg-offset-6 {
    margin-left: 50%;
  }

  .fw-col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .fw-col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .fw-col-lg-offset-3 {
    margin-left: 25%;
  }

  .fw-col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .fw-col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .fw-col-lg-offset-0 {
    margin-left: 0%;
  }
}









/* top video css starts */

.top-video-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.top-video-section {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  background-color: black;
  min-height: 100vh;
}


.register-section {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.video-overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}


.privacy-policy-para {
  color: white;
  padding: 15px;
  /* Adds space inside the box */
  border: 1px solid transparent;
  /* Invisible border */
  box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  /* Rounded corners, optional */
}


.privacy-policy-content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;

}

.overlay-text-simplifies {
  color: white;
  text-align: center;
  font-family: Rubik;
  font-size: 100px;
  font-weight: 800;
  line-height: 100px;
  color: rgba(255, 255, 255, 1);
}

.privacy-policy-heading {
  color: white;
  text-align: center;
  font-family: Rubik;
  font-size: 50px;
  font-weight: 500;
  line-height: 50px;
  color: rgba(255, 255, 255, 1);
}

.privacy-policy-content {
  color: white;
  text-align: left;
  font-family: Rubik;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  color: rgba(255, 255, 255, 1);
}

.overlay-text-available {
  font-family: Rubik;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  margin-top: 20px;
  color: rgba(255, 255, 255, 1);
}


.text-ratings {
  font-family: Poppins;
  font-size: 60px;
  font-weight: 400;
  line-height: 72px;
  text-align: left;
  color: rgba(255, 255, 255, 1);

}

.text-reviews {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: left;
  color: rgba(255, 255, 255, 1);

}


.img-appstore {
  margin-top: 15px;
}

.img-reviews {
  margin-top: 15px;
}

.separator-2 {
  width: 1px;
  border: 1px solid rgba(70, 70, 71, 1);
  margin: 0 10px;
}

.separator-1 {
  width: 1px;
  border: 1px solid rgba(70, 70, 71, 1);
  margin: 0 20px;
}

.reviews-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.navbar-frame {
  padding: 20px 30px;
  gap: 0px;
  border-radius: 90px;
  background: rgba(255, 255, 255, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.navbar-left-content {
  order: 1;
}

.navbar-right-content {
  order: 2;
}

.navbar-right-text {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: center;
  color: rgba(255, 255, 255, 1) !important;

}

.icon-call-sms {
  margin-right: 10px;
}

.product_name_hover {
  transition: color 0.3s ease-in-out;
  /* Smooth color transition */
}

.product_name_hover:hover {
  text-decoration: underline;
  color: #ff0000;
}

.emailid {
  color: #ffffff;
}

.emailid_footer,
.mobile {
  color: #000;
}

@media only screen and (max-width: 399px) {


  .vector-class {
    display: none;
  }

  .navbar-frame {
    padding: 8px;

  }

  .navbar-left-content {
    width: 80px;
    height: 30px;
  }

  .navbar-right-text {
    font-size: 9px;
  }

  .separator-1 {
    margin: 0 10px;
  }

  .icon-call-sms {
    height: 10px;
    width: 10px;
  }

  .overlay-text-simplifies {
    font-size: 30px;
    line-height: 34px;
  }

  .img-appstore {
    width: 80px;
    height: 30px;
    margin-top: 8px;
  }

  .img-reviews {
    width: 100px;
    height: 50px;
    margin-top: 0px;
  }

  .text-ratings {
    font-size: 40px;
  }

  .overlay-text-available {
    font-size: 11px;
  }

  .img-reviews {
    width: 70px;
    height: 18px;
    margin-top: 20px;
  }

  .text-ratings {
    font-size: 22px;
  }

  .text-reviews {
    font-size: 10px;
  }

  .reviews-container {

    margin-top: 11px;
  }

  .privacy-policy-content {
    font-size: 11px;
    line-height: 18px;
  }

  .privacy-policy-heading {
    font-size: 24px;
    line-height: 25px;
  }
}


@media only screen and (min-width: 400px) and (max-width: 767px) {

  .vector-class {
    display: none;
  }

  .navbar-frame {
    padding: 15px;

  }

  .navbar-left-content {
    width: 100px;
    height: 30px;
  }

  .navbar-right-text {
    font-size: 11px;
  }

  .separator-1 {
    margin: 0 10px;
  }

  .icon-call-sms {
    height: 15px;
    width: 15px;
  }

  .overlay-text-simplifies {
    font-size: 50px;
    line-height: 59px;
  }

  .img-appstore {
    width: 130px;
    height: 40px;
  }

  .img-reviews {
    width: 100px;
    height: 50px;
    margin-top: 0px;
  }

  .text-ratings {
    font-size: 40px;
  }

  .overlay-text-available {
    font-size: 14px;
  }

  .img-reviews {
    width: 90px;
    height: 42px;
    margin-top: 0px;
  }

  .text-ratings {
    font-size: 35px;
  }

  .privacy-policy-content {
    font-size: 11px;
    line-height: 18px;
  }

  .privacy-policy-heading {
    font-size: 24px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overlay-text-simplifies {
    font-size: 80px;
    line-height: 80px;
  }

  .img-appstore {
    width: 150px;
    height: 50px;
  }

  .img-reviews {
    width: 100px;
    height: 50px;
    margin-top: 0px;
  }

  .text-ratings {
    font-size: 40px;
  }

  .privacy-policy-content {
    font-size: 12px;
    line-height: 20px;
  }

  .privacy-policy-heading {
    font-size: 32px;
    line-height: 30px;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {


  .privacy-policy-content {
    font-size: 12px;
    line-height: 20px;
  }

}



@media only screen and (min-width: 1200px) {
  .overlay-text-simplifies {
    font-size: 120px;
  }
}

/* top video css ends */










/* red bg css starts */

.red-image-section {
  /* background-image: url('../public/assets/section-red/redbg.png'); */
  width: 100%;
  min-height: 100vh;
  /* position: relative; */
  background-size: cover;
  background-position: center center;
  border-radius: 50px 50px 0 0;
  background: #e92b31;
}


.divider-space-red-1 {
  margin-top: 25vh;
}



.red-text-about-us {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 800;
  line-height: 18.96px;
  text-align: left;
  color: rgba(1, 1, 1, 1);


}


.red-text-hope-you {
  font-family: Rubik;
  font-size: 58px;
  font-weight: 800;
  line-height: 84px;
  text-align: left;
  color: rgba(255, 255, 255, 1);

}

.red-text-hope-you span {
  opacity: 60%;
}

.red-text-but-if {
  font-family: Rubik;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: rgba(255, 255, 255, 1);


}

.red-text-most {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: left;
  color: rgba(255, 255, 255, 1);

}


.red-dot-img {
  margin-left: -100px;
  height: 80px;
  width: 80px;
}

.red-triangle-img {
  margin-top: -380px;
  margin-left: -40px;
}

.sliding-text-content {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.sliding-text-content.show {
  opacity: 1;
  transform: translateX(0);
}


.sliding-mobile-content {
  opacity: 0;
  transform: translateY(50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.sliding-mobile-content.show {
  opacity: 1;
  transform: translateX(0);
}


.sliding-triangle-content {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.sliding-triangle-content.show {
  opacity: 1;
  transform: translateX(0);
}




.play-store-icon {
  height: 90px;
}

.divider-space-yellow-1 {
  margin-top: 10vh;
}


@media only screen and (max-width: 399px) {


  .divider-space-red-1 {
    margin-top: 10vh;
  }

  .red-text-hope-you {
    font-size: 22px;
    line-height: 34px;
  }

  .red-text-but-if {
    font-size: 11px;
    line-height: 20px;
  }

  .red-dot-img {
    height: 25px;
    width: 25px;
    margin-left: -70px;
  }

  .red-triangle-img {
    height: 200px;
    margin-top: -150px;
    margin-left: -50px;
    width: 140px;
  }

  .red-mobile-img {
    height: 400px;
  }

  .slider-col-red {
    margin-top: 30px;
  }


}

@media only screen and (min-width: 400px) and (max-width: 767px) {

  .divider-space-red-1 {
    margin-top: 10vh;
  }

  .red-text-hope-you {
    font-size: 34px;
    line-height: 41px;
  }

  .red-text-but-if {
    font-size: 16px;
    line-height: 24px;
  }

  .red-dot-img {
    height: 35px;
    width: 35px;
    margin-left: -70px;
  }

  .red-triangle-img {
    height: 200px;
    margin-top: -150px;
    margin-left: -50px;
    width: 140px;
  }

  .red-mobile-img {
    height: 400px;
  }

  .slider-col-red {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {


  .red-triangle-img {
    margin-top: -300px;
    margin-left: -30px;

  }

  .red-text-hope-you {
    font-size: 35px;
    line-height: 54px;
  }

  .red-text-but-if {
    font-size: 16px;
    line-height: 30px;
  }

  .red-dot-img {
    height: 45px;
    width: 45px;
    margin-left: -70px;
  }

  .divider-space-yellow-1 {
    margin-top: 10vh;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .red-triangle-img {
    margin-top: -300px;
    margin-left: -30px;

  }

  .red-text-hope-you {
    font-size: 47px;
    line-height: 72px;
  }

  .red-text-but-if {
    font-size: 22px;
    line-height: 36px;
  }

  .red-dot-img {
    height: 65px;
    width: 65px;
  }
}



@media only screen and (min-width: 1200px) {}


/* red bg css ends */




/* yellow bg css start */
.yellow-image-section {
  /* background-image: url('../public/assets/section-yellow/bgyellow.png'); */
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background: #d79618;
}




.yellow-text-lets-find {
  font-family: Rubik;
  font-size: 16px;
  font-style: italic;
  font-weight: 800;
  line-height: 18.96px;
  text-align: left;
  color: rgba(1, 1, 1, 1);
}

.yellow-text-how-our {
  font-family: Rubik;
  font-size: 70px;
  font-weight: 800;
  line-height: 84px;
  text-align: left;
  color: black;

}

.yellow-qr-img {
  display: block;
  margin: auto;
  width: 100%;
}

.yellow-text-how-our span {
  color: rgba(205, 3, 10, 0.6);

}


.yellow-layer-img {
  position: absolute;
  z-index: 0;
  bottom: -20px;
  left: -35px;
  height: 250px;
  width: 250px;
}

.sliding-qr-content {
  opacity: 0;
  transform: translateY(50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.sliding-qr-content.show {
  opacity: 1;
  transform: translateX(0);
}

.sliding-text-content-yellow {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.sliding-text-content-yellow.show {
  opacity: 1;
  transform: translateX(0);
}

.sliding-layer-content-yellow {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.sliding-layer-content-yellow.show {
  opacity: 1;
  transform: translateX(0);
}




.faq-container {

  margin: auto;
}

.faq-item {

  margin-bottom: 10px;
}

.faq-question {

  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(1, 1, 1, 1);

}

.faq-answer {
  padding: 10px;
  display: none;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: left;
  color: rgba(1, 1, 1, 1);
}

.faq-answer.open {
  display: block;
}

.toggle-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}






@media only screen and (max-width: 399px) {

  .yellow-text-lets-find {

    font-size: 12px;

  }

  .yellow-text-how-our {
    font-size: 27px;
    line-height: 30px;
  }

  .divider-space-yellow-1 {
    margin-top: 10vh;
  }


  .faq-question {
    font-size: 12px;
    line-height: 14px;
    padding: 1px;
  }

  .faq-answer {
    font-size: 12px;
    line-height: 14.96px;

  }

  .yellow-qr-img {
    margin-top: 50px;
    height: 200px;
  }

  .yellow-layer-img {
    height: 150px;
    width: 150px;
    bottom: 49px;
    left: -23px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
  .divider-space-yellow-1 {
    margin-top: 10vh;
  }

  .yellow-text-how-our {
    font-size: 41px;
    line-height: 48px;
  }

  .faq-question {
    font-size: 15px;
    line-height: 19px;
    padding: 5px;
  }

  .faq-answer {
    font-size: 12px;
    line-height: 14.96px;

  }

  /* 
  .yellow-qr-img {
    height: 230px;
    width: 230px;
  } */



  .yellow-layer-img {
    position: absolute;
    z-index: 0;
    bottom: 29px;
    left: -27px;
    height: 180px;
    width: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .yellow-text-how-our {
    font-size: 46px;
    line-height: 56px;
  }

  .faq-question {
    font-size: 16px;
    line-height: 20px;
  }

  .faq-answer {
    font-size: 13px;
    line-height: 14.96px;
  }

  .yellow-layer-img {
    position: absolute;
    z-index: 0;
    bottom: 29px;
    left: -35px;
    height: 250px;
    width: 250px;
  }


}


@media only screen and (min-width: 992px) and (max-width: 1199px) {


  .yellow-layer-img {
    position: absolute;
    z-index: 0;
    bottom: 38px;
    left: -35px;
    height: 250px;
    width: 250px;
  }
}



@media only screen and (min-width: 1200px) {



  .yellow-layer-img {
    position: absolute;
    z-index: 0;
    bottom: -20px;
    left: -35px;
    height: 250px;
    width: 250px;
  }
}



/* yellow css ends here */




/* green content starts */

.green-image-section {
  /* background-image: url('../public/assets/section-green/bg-green.png'); */
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background: linear-gradient(to right, #3ecf27, #2abb1a);
}

.green-text-prod {
  font-family: Rubik;
  font-size: 16px;
  font-style: italic;
  font-weight: 800;
  line-height: 18.96px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-align: center;

}

.green-text-ultimate {
  font-family: Rubik;
  font-size: 70px;
  font-weight: 800;
  line-height: 84px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}



.green-text-ultimate span {
  opacity: 60%;
}

.green-products-frame {
  overflow: hidden;
  position: relative;
  background-position: center;
  width: 100;
  display: flex;
}

.green-product-single {
  flex: 1;
  text-align: center;
}

.green-products-image {
  width: 250px;
  height: 250px;
}

.green-products-title {
  font-family: Rubik;
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-top: 20px;

}

.centered-frame-products {
  display: flex;
  justify-content: center;
  width: 85%;
  /* Width of the centered frame */
  margin: 0 auto;
  /* Center horizontally */
  margin-top: 10px;
  gap: 10px;
  position: relative;
  z-index: 1;
}

.green-button-frame {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 60px;
  /* opacity: 0px; */
  border: 2px solid rgba(255, 255, 255, 1)
}


.green-cart-svg {
  width: 20px;
  height: 20px;
  background-image: url('../public/assets/section-green/whiteVectorcart.svg');
  background-size: contain;
  margin-right: 5px;
}

.green-buy-now {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin: 0;
}


.green-button-frame:hover {
  background: rgba(255, 255, 255, 1);
  transition: 0.3s ease-in;
}

.green-button-frame:hover .green-buy-now {
  color: rgba(1, 1, 1, 1);
  transition: 0.3s ease-in;
}

.green-button-frame:hover .green-cart-svg {
  background-image: url('../public/assets/section-green/blackVectorCart.svg');
  transition: 0.3s ease-in;
}


.green-stack-img {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  height: 450px;
}

.green-half-circle-img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 217px;
  height: 400px
}


.green-unfill-circle-img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 180px;
  height: 300px;

}

.sliding-products-content-green {
  opacity: 0;
  transform: translateY(50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.sliding-products-content-green.show {
  opacity: 1;
  transform: translateX(0);
}

.sliding-text-content-green {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.sliding-text-content-green.show {
  opacity: 1;
  transform: translateX(0);
}

.sliding-stack-content-green,
.sliding-circle-content-green {
  opacity: 0;
  transform: translateY(50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.sliding-stack-content-green.show,
.sliding-circle-content-green.show {
  opacity: 1;
  transform: translateX(0);
}

/* checkout Page css */
.checkout_card {
  border: 2px solid #ffffff1a;
  /* border-radius: 12px; */
  background: #ffffff1c !important;
}

.product_detail .product_name,
.product_price {
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
}

.coupon_input {
  background: transparent;
  border: 2px solid #ffffff29;
  height: 40px;
  border-radius: 6px;
}

.coupon_input input {
  background: transparent;
  border: none;
  height: 100%;
  color: #fff;
  padding-left: 10px;
}

.coupon_input button {
  border: none;
  color: #fff;
  font-size: 18px;
  background: #cd030a;
}


.coupon_input input::placeholder {
  color: #ffffff80;
}

.left_heading,
.right_text {
  font-size: 18px;
  font-weight: 500;
}

.total_amount {
  font-size: 20px;
  font-weight: 500;
}

.left_heading {
  color: #919191;
}

.payment_detail {
  border-bottom: 1px solid #ffffff3b;
}

.place_order_btn button,
.place_order_btn span,
.place_order_btn a {
  width: 100%;
  padding: 6px 10px;
  background: #cd030a;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}

@media only screen and (max-width: 399px) {





  .green-text-prod {

    font-size: 13px;

  }

  .green-text-ultimate {

    font-size: 21px;
    line-height: 30px;

  }

  .green-products-title {
    font-size: 14px;
  }

  .green-products-image {
    width: 100%;
    height: auto;
  }

  .green-products-frame {
    gap: 10px;
  }


  .green-products-title {
    font-size: 14px;
    line-height: 11px;
  }

  .green-button-frame {
    padding: 8px;
    gap: 0px;
  }

  .green-buy-now {
    font-size: 12px;
  }

  .green-cart-svg {
    width: 15px;
    height: 15px;
  }


  .green-stack-img {
    height: 259px;
  }

  .green-half-circle-img {

    width: 120px;
    height: 200px;
  }

  .green-unfill-circle-img {

    width: 90px;
    height: 120px;
  }


}

@media only screen and (min-width: 400px) and (max-width: 499px) {

  .green-text-prod {

    font-size: 13px;

  }

  .green-text-ultimate {

    font-size: 22px;
    line-height: 30px;

  }

  .green-products-image {
    width: 100%;
    height: auto;
  }

  .green-products-frame {
    gap: 10px;
  }


  .green-products-title {
    font-size: 10px;
    line-height: 8px;
  }

  .green-button-frame {
    padding: 8px;
    gap: 0px;
  }

  .green-buy-now {
    font-size: 14px;
  }

  .green-cart-svg {
    width: 15px;
    height: 15px;
  }

  .green-stack-img {
    height: 259px;
  }

  .green-half-circle-img {

    width: 150px;
    height: 220px;
  }

  .green-unfill-circle-img {

    width: 120px;
    height: 150px;
  }

}

@media only screen and (min-width: 500px) and (max-width: 767px) {

  .green-text-prod {

    font-size: 13px;

  }

  .byAmazone,
  .byLifecode {
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
    padding: 3px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    min-height: 40px;
    width: 100%;
    margin-bottom: 10px;
  }

  .green-text-ultimate {

    font-size: 26px;

  }

  .green-products-image {
    width: 100%;
    height: auto;
  }

  .green-products-frame {
    gap: 10px;
  }


  .green-products-title {
    font-size: 10px;
    line-height: 8px;
  }

  .green-button-frame {
    padding: 10px 10px 10px 10px;
  }

  .green-buy-now {
    font-size: 12px;
  }

  .green-cart-svg {
    width: 18px;
    height: 18px;
  }


  .green-stack-img {
    height: 259px;
  }

  .green-half-circle-img {

    width: 150px;
    height: 220px;
  }

  .green-unfill-circle-img {

    width: 120px;
    height: 150px;
  }


}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .green-text-ultimate {

    font-size: 47px;
    line-height: 50px;
  }

  .green-products-image {
    width: 190px;
    height: 190px;
  }


  .green-products-title {
    font-size: 19px;
    line-height: 18px;
  }

  .green-button-frame {
    padding: 10px 30px 10px 30px;
  }

  .green-buy-now {
    font-size: 14px;
  }

  .green-cart-svg {
    width: 18px;
    height: 18px;
  }

  .green-stack-img {
    height: 259px;
  }

  .green-half-circle-img {

    width: 150px;
    height: 220px;
  }

  .green-unfill-circle-img {

    width: 120px;
    height: 150px;
  }


}

@media only screen and (min-width: 992px) and (max-width: 1199px) {


  .green-text-ultimate {

    font-size: 60px;

  }

  .green-stack-img {
    height: 299px;
  }

  .green-half-circle-img {

    width: 209px;
    height: 295px;
  }

  .green-unfill-circle-img {

    width: 140px;
    height: 200px;
  }
}

@media only screen and (min-width: 1200px) {
  .centered-frame-products {
    width: 70%;
  }
}


/* footer css starts */
.footer-cont {

  border-radius: 40px 40px 0 0;
  background-color: white;
  width: 100%;
}

.footer-logo {
  margin-bottom: 20px;
}


.footer-head-text {

  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
  color: rgba(1, 1, 1, 1);
  margin-bottom: 15px;
}

.footer-sub-text {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
  color: rgba(1, 1, 1, 1) !important;
  margin-top: 10px;
  margin-bottom: 10px;

}

.footer-sub-icon {
  margin-right: 10px;
}

.icon-text-cont {
  display: flex;
  align-items: center;
}

/* header css */
.cart_badge {
  background: #fff;
  height: 22px;
  width: 22px;
  border-radius: 40px;
  color: #000;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -8px;
  margin-left: 8px;
}


@media only screen and (max-width: 399px) {
  .centered-frame-products {
    width: 94%;
  }

  .navbar-right-content {
    gap: 6px;
  }

  .centered-frame-products {
    display: block;
    justify-content: center;
    width: 94%;
    margin: 0 auto;
    margin-top: 10px;
    gap: 10px;
    position: relative;
    z-index: 1;
  }

  .footer-sub-icon {
    height: 15px;
    width: 15px;
  }

  .footer-head-text {

    font-size: 18px;

    margin-bottom: 5px;
  }


  .footer-logo {
    margin-bottom: 10px;
  }

  .footer-sub-text {
    font-size: 10px;
    line-height: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .footer-logo {
    width: 150px;
    height: 50px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
  .centered-frame-products {
    width: 94%;
  }

  .navbar-right-content {
    gap: 6px;
  }

  .centered-frame-products {
    display: block;
    justify-content: center;
    width: 94%;
    margin: 0 auto;
    margin-top: 10px;
    gap: 10px;
    position: relative;
    z-index: 1;
  }

  .footer-head-text {

    font-size: 18px;

    margin-bottom: 5px;
  }


  .footer-logo {
    margin-bottom: 10px;
  }

  .footer-sub-text {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .footer-logo {
    width: 150px;
    height: 50px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order_from_amazone {
    height: 30px;
    width: 70px;
  }

  .centered-frame-products {
    width: 94%;
  }

  .footer-sub-text {

    font-size: 15px;
  }

  .footer-head-text {

    font-size: 22px;

  }




  .footer-logo {
    width: 150px;
    height: 50px;
  }



}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (min-width: 1200px) {}

/* shipping address Page css */
.billing_address_page .bg_pic ::placeholder {
  color: #ffffff70 !important;
}

.form-control {
  border: 1px solid #ffffff30;
}

.billing_address_page .hedding_product h2 {
  text-align: center;
  font-size: 45px;
  margin: 0;
  color: #fff;
}

.billing_address_page .from_wepper {
  max-width: 900px;
  margin: 30px auto 0;
  border: solid 2px #ffffff30;
  padding: 20px 20px 0;
  background: #ffffff1c !important;
}

.billing_address_page .from_wepper .form-control {
  background-color: transparent !important;
  padding: 6px 15px;
  color: #fff !important;
  border: 1px solid #ffffff30 !important;
}

.billing_address_page .from_wepper .form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}

.billing_address_page .from_wepper p {
  background-color: #8080808a;
  padding: 10px;
  font-size: 15px;
  color: #fff;
  border-radius: 8px;
}

.billing_address_page .from_wepper span {
  color: #fff;
  margin-bottom: 10px;
  display: block;
}


.billing_address_page .from_wepper .form-group {
  margin-bottom: 20px;
}

.billing_address_page .from_wepper .form-group select {
  background: transparent;
  color: #ffffff70;
  padding: 6px 13px;
  width: 100%;
  border: 1px solid #ffffff30;
  border-radius: 5px;
}

.billing_address_page .from_wepper .form-group select .option {
  background-color: #000 !important;
}

.billing_address_page .button_con {
  border: solid 1px #ffffff30;
  width: 40%;
  margin: auto;
  padding: 10px 45px;
  margin-top: 20px;
  background-color: transparent;
  color: #fff;
  border-radius: 6px;
  font-weight: 600;
  text-transform: uppercase;
}

.billing_address_page .button_con:hover {
  background-color: #cd030a;
  color: #ffffff;
}

.form-control:focus {
  border-color: #caa746;
  box-shadow: unset;
}

@media(max-width:570px) {
  .billing_address_page .bg_pic {
    height: auto;
    padding-bottom: 40px;
  }

  .billing_address_page .hedding_product {
    padding-top: 28px;
  }
}